<!-- NOTE: classの命名がルール外なので流用時は気を付けてください -->
<template>
	<div class="lp">
		<h1 class="lp__header">
			<img src="~@/assets/img/lp_typhoon2021/lp_typhoon_title.png" alt="台風が近ずくと発症！台風頭痛に注意">
			<p><span>presented by</span><i class="sprite_typhoon logo" /></p>
		</h1>

		<section class="lp__intro">
			<p class="lp__intro--text">台風が近づくとなんだか頭が痛い、体が重い…<br>もしかしてそれは「台風頭痛」かも！<br>頭痛ーるで台風頭痛対策をしよう！</p>
		</section>

		<section class="lp__link">
			<div class="lp__link--item" @click="onScroll('js_content01')">
				<p><i class="lp__link--item-icon sprite_typhoon typhoon" /><span>台風頭痛ってなに？簡単予防法も</span></p>
			</div>
			<div class="lp__link--item" @click="onScroll('js_content02')">
				<p><i class="lp__link--item-icon sprite_typhoon phone" /><span>頭痛ーるで台風頭痛に備えよう！</span></p>
			</div>
			<div class="lp__link--item" @click="onScroll('js_content03')">
				<p><i class="lp__link--item-icon sprite_typhoon present" /><span>台風をやっつけよう！<br>ゲームクリアでスタンププレゼント</span></p>
			</div>
		</section>

		<!-- 記事 -->
		<section class="lp__article">
			<div class="lp__article--wrapper" id="js_content01">
				<div class="lp__article--wrapper-title">
					<h2>台風が近づくと発症する<br>「台風頭痛」とは？</h2>
					<i class="sprite_typhoon title_tyhoon" />
				</div>
				<div class="lp__article--wrapper-content1">
					<div class="is-first">
						<img src="~@/assets/img/lp_typhoon2021/lp_typhoon_image1.png" alt="頭痛、落ち込み、肩こり、だるさ、むくみ">
						<p>台風で頭痛が起こる要因はなに？予防はできる？台風頭痛について、医師が分かりやすく解説します。</p>
					</div>
					<a href="javascript:void(0);" @click="onColumnLink($event, 95)" id="trackingTyphoon2021Btn01">
						<i class="sprite_typhoon article_btn" />
					</a>
					<div class="is-second">
						<img src="~@/assets/img/lp_typhoon2021/lp_typhoon_massage.png" alt="マッサージ">
						<p>台風頭痛にもオススメ！1分で予防出来る「くるくる耳マッサージ」をやってみましょう。</p>
					</div>
					<a href="javascript:void(0);" @click="onColumnLink($event, 83)" id="trackingTyphoon2021Btn02">
						<i class="sprite_typhoon manner_btn" />
					</a>
				</div>
			</div>
			<div class="lp__article--wrapper" id="js_content02">
				<div class="lp__article--wrapper-title">
					<h2>台風シーズンの<br>頭痛ーるの使い方</h2>
					<i class="sprite_typhoon title_phone" />
				</div>
				<div class="lp__article--wrapper-content2">
					<div class="is-first">
						<img src="~@/assets/img/lp_typhoon2021/lp_typhoon_graph_anime.gif" alt="グラフ" class="graph">
						<p>頭痛ーるは、気圧が下がると、気圧グラフに警戒マークが出ます。気圧グラフは6日先まで見られるので、「薬を飲む」「予定を変更する」などの事前の対策ができます。</p>
						<div class="is-first-img">
							<img src="~@/assets/img/lp_typhoon2021/lp_typhoon_medicine.png" alt="カレンダー">
							<img src="~@/assets/img/lp_typhoon2021/lp_typhoon_calendar.png" alt="薬">
						</div>
					</div>
					<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" id="trackingTyphoon2021Btn03_Android">
						<i class="sprite_typhoon graph_btn" />
					</a>
					<a v-else href="javascript:void(0);" @click="onGraphLink($event)" id="trackingTyphoon2021Btn03_IOS">
						<i class="sprite_typhoon graph_btn" />
					</a>
					<div class="is-second">
						<p>特に台風は接近する前から気圧が大きく低下し始めるので早めの対策が必要です。また、普段よりも気圧が急激に低下するため、体調不良になる人が多いです。普段気圧の影響を感じない方も気をつけておくと安心です。</p>
						<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" id="trackingTyphoon2021Btn04_Android">
							<img src="~@/assets/img/lp_typhoon2021/lp_typhoon_push.png" alt="いますぐダウンロード" class="push">
						</a>
						<a v-else href="javascript:void(0);" @click="onAppDwnloadLink($event)" id="trackingTyphoon2021Btn04_IOS">
							<img src="~@/assets/img/lp_typhoon2021/lp_typhoon_push.png" alt="いますぐダウンロード" class="push">
						</a>
						<img src="~@/assets/img/lp_typhoon2021/lp_tyhoon_how_to_use_title.png" alt="頭痛ーるの使い方や便利の機能を知りたい方はこちら！" class="title">
						<img src="~@/assets/img/lp_typhoon2021/lp_typhoon_how_to_use.png" alt="初心者向け！頭痛ーるアプリ基本の使い方" class="howtouse">
					</div>
					<a href="javascript:void(0);" @click="onColumnLink($event, 580)" id="trackingTyphoon2021Btn05">
						<i class="sprite_typhoon howtouse_btn" />
					</a>
				</div>
			</div>
			<div class="lp__article--wrapper" id="js_content03">
				<div class="lp__article--wrapper-title">
					<h2>台風をやっつけよう！<br>ゲームクリアでスタンププレゼント</h2>
					<i class="sprite_typhoon title_present" />
				</div>
				<div class="lp__article--wrapper-content3">
					<div>
						<img src="~@/assets/img/lp_typhoon2021/lp_typhoon_game.png" alt="ゲーム">
						<p>台風で外出できない…最近ストレスがたまる…そんなときに「台風やっつけゲーム」はいかがでしょうか。ゲームクリアすると、頭痛ーるキャラクターの台風スタンプをプレゼント！</p>
						<div class="stamp-img">
							<img src="~@/assets/img/stamp/typhoon202108_stamp02.png" alt="強風">
							<img src="~@/assets/img/stamp/typhoon202108_stamp05.png" alt="やまない雨はナイ">
							<img src="~@/assets/img/stamp/typhoon202108_stamp07.png" alt="忘れずに！">
						</div>
					</div>
					<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=game` } })" id="trackingTyphoon2021Btn06">
						<i class="sprite_typhoon game_btn" />
					</a>
				</div>
			</div>
		</section>

		<!-- TOPにもどる -->
		<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" id="trackingTyphoon2021Btn07">
			<span class="back_button">TOPにもどる</span>
		</a>

		<!-- 下に吸着するアプリダウンロードボタン -->
		<aside class="sticky-bottom-appdownload">
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" id="trackingTyphoonLpStickyBtn_android" class="sticky-bottom-appdownload--button">
				<img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード" />
			</a>
			<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" id="trackingTyphoonLpStickyBtn_iOS" class="sticky-bottom-appdownload--button">
				<img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード" />
			</a>
		</aside>
	</div>
</template>

<script>

// Vuex
import { mapGetters } from 'vuex'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'LpScratch2021',
	components: {
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	mounted () {
		// コンテンツの高さをemitする処理（実処理は親で行う）
		// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
		// this.$nextTick(() => {
		// 	this.$emit('contentHeight')
		// })
	},
	beforeDestroy () {
	},
	methods: {
		/**
		 * ページスクロール用の関数
		 */
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		},
		/**
		 * コラムリンク
		 */
		onColumnLink (event, id) {
			event.preventDefault()
			this.$router.push({ name: 'Cpsite', query: { url: `${this.backendUrl}?_path=columnDetail`, _detailId: id } })
		},
		/**
		 * 気圧グラフをみる（iOS）
		 */
		onGraphLink (event) {
			event.preventDefault()
			this.$router.push({ name: 'Cpsite', query: { url: `${this.backendUrl}?_path=weatherGraph` } })
		},
		/**
		 * アプリダウンロードリンク（iOS）
		 */
		onAppDwnloadLink (event) {
			event.preventDefault()
			this.$router.push({ name: 'Cpsite', query: { url: `${this.backendUrl}?_path=appDownload`, _backPage: 'top' } })
		}
	}
}
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/variable";

// NOTE: このページ限定の変数（デザインシステム外のため他での利用禁止）
$spacing-9: 0.5625rem;
$spacing-12: 0.75rem;
$spacing-14: 0.875rem;
$spacing-15: 0.937rem;
$spacing-17: 1.0625rem;
$spacing-18: 1.125rem;
$spacing-25: 1.5625rem;
$spacing-26: 1.625rem;
$spacing-28: 1.75rem;
$spacing-29: 1.8125rem;
$spacing-38: 2.375rem;
$spacing-39: 2.4375rem;
$spacing-42: 2.625rem;
$spacing-46: 2.875rem;
$spacing-70: 4.375rem;
$lp-primary-color: #10A8B9;
$font-size-15: 0.937rem;
$font-size-17: 1.0625rem;

.lp {
	background: url(~@/assets/img/lp_typhoon2021/bg.png) repeat-y 0 0;
	background-size: 100% auto;
	box-sizing: border-box;
	font-family: $lp-family-primary;
	&__header {
		width: 100%;
		margin: 0;
		position: relative;
		& > img {
			width: 100%;
			padding: 0;
			vertical-align: top;
		}
		& > p {
			position: absolute;
			bottom: -5px;
			right: 0;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			margin: 0;
			padding-right: $spacing-6;
			font-weight: normal;
			& > span {
				margin-right: $spacing-4;
				font-size: $font-size-10;
			}
		}
		&--icon {
			width: 77px;
			height: 17px;
		}
	}
	&__intro {
		margin-top: $spacing-25;
		font-size: $font-size-16;
		color: $text-primary;
		&--text {
			font-family: $lp-family-tertiary;
			text-align: center;
		}
	}
	&__link {
		width: 100%;
		margin-top: $spacing-29;
		padding: 0 $spacing-14;
		box-sizing: border-box;
		&--item {
			width: 100%;
			height: 70px;
			display: flex;
			justify-content: left;
			border: 2px solid $lp-primary-color;
			border-radius: 10px;
			background-color: $background-primary;
			box-shadow: 0px 3px $lp-primary-color;
			& > p {
				display: flex;
				align-items: center;
				margin: 0;
				& > span {
					margin-left: $spacing-9;
					font-size: $font-size-16;
					font-family: $lp-family-secondary;
					color: $lp-primary-color;
				}
			}
			&-icon {
				margin-left: $spacing-15;
			}
		}
		&--item:not(:nth-child(1)) {
			margin-top: $spacing-16;
		}
	}
	&__article {
		width: 100%;
		padding: 0 $spacing-14;
		margin-top: $spacing-70;
		box-sizing: border-box;
		&--wrapper {
			width: 100%;
			&-title {
				width: 100%;
				display: flex;
				justify-content: center;
				text-align: center;
				background-color: $lp-primary-color;
				border-radius: 10px 10px 0 0;
				position: relative;
				& > h2 {
					margin: 1.75rem 0 1.25rem;
					font-size: $font-size-20;
					color: $text-seconday;
					font-family: $lp-family-primary;
					font-weight: normal;
				}
				& > i {
					position: absolute;
					top: -35px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
			&-content1 {
				border: 3px solid $lp-primary-color;
				border-top: none;
				border-radius: 0 0 10px 10px;
				background-color: $background-primary;
				.is-first {
					padding: $spacing-18 $spacing-14 0;
					img {
						max-width: 299px;
					}
				}
				.is-second {
					padding: 0 $spacing-14;
					margin: $spacing-38 0 0;
					img {
						max-width: 312px;
					}
					p {
						padding-top: $spacing-10;
					}
				}
				img {
					width: 100%;
					display: block;
					margin: 0 auto;
				}
				p {
					font-family: $lp-family-primary;
					font-size: $font-size-15;
					padding: $spacing-12 0 $spacing-16;
					margin: 0;
				}
				i {
					width: 270px;
					height: 53px;
					display: block;
					margin: 0 auto;
				}
			}
			&-content2 {
				border: 3px solid $lp-primary-color;
				border-top: none;
				border-radius: 0 0 10px 10px;
				background-color: $background-primary;
				p {
					font-family: $lp-family-primary;
					font-size: $font-size-15;
					margin: 0;
				}
				.is-first {
					padding: $spacing-25 $spacing-14 0;
					& > p {
						padding: $spacing-12 0 $spacing-16;
					}
					.graph {
						max-width: 271px;
						width: 100%;
						display: block;
						margin: 0 auto;
					}
					&-img {
						width: 100%;
						display: flex;
						flex-direction: row;
						justify-content: space-evenly;
						align-items: flex-start;
						img {
							max-width: 131px;
							width: 100%;
						}
					}
				}
				.is-second {
					padding: 0 $spacing-14;
					& > p {
						padding-bottom: $spacing-16;
					}
					.push {
						max-width: 317px;
						width: 100%;
						display: block;
						margin: 0 auto;
					}
					.title {
						max-width: 307px;
						width: 100%;
						display: block;
						margin: $spacing-39 auto $spacing-12;
					}
					.howtouse {
						max-width: 320px;
						width: 100%;
						display: block;
						margin: 0 auto;
					}
				}
				& > i {
					width: 270px;
					height: 53px;
					display: block;
				}
			}
			&-content3 {
				border: 3px solid $lp-primary-color;
				border-top: none;
				border-radius: 0 0 10px 10px;
				background-color: $background-primary;
				padding-bottom: $spacing-39;
				& > div {
					padding: $spacing-28 $spacing-14 0;
					& > img {
						max-width: 290px;
						width: 100%;
						display: block;
						margin: 0 auto;
					}
					& > p {
						font-family: $lp-family-primary;
						font-size: $font-size-15;
						padding: $spacing-25 0 $spacing-20;
						margin: 0;
					}
					.stamp-img {
						box-sizing: border-box;
						display: flex;
						flex-direction: row;
						justify-content: space-evenly;
						align-items: flex-start;
						img {
							max-width: 96px;
							width: 33%;
						}
					}
				}
			}
		}
		&--wrapper:not(:nth-child(1)) {
			margin-top: 5rem;
		}
	}
	.back_button {
		display: block;
		font-size: 1.125rem;
		font-weight: bold;
		line-height: 1.4;
		margin: $spacing-46 auto 0;
		padding: 0.5rem 0;
		max-width: 300px;
		width: 100%;
		text-align: center;
		color: #FFF;
		border-radius: 6px;
		background-color: #FE8F11;
		text-decoration: none;
	}
}
.sticky-bottom-appdownload {
	margin-top: $spacing-20;
	padding: 0;
}

@media screen and (max-width:320px) {
	.lp {
		&__intro {
			font-size: $font-size-14;
		}
		&__link--item p span {
			font-size: $font-size-12;
		}
		&__article--wrapper {
			p {
				font-size: $font-size-12 !important;
			}
			&-title h2 {
				font-size: $font-size-17;
			}
		}
	}
}

// スプライト
.sprite_typhoon {
	background-image: url(~@/assets/img/lp_typhoon2021/sprite_typhoon.png);
	background-repeat: no-repeat;
	display: block;
	&.logo {
		background-size: (640px / 2) (1812px / 2);
		width: (157px / 2);
		height: (40px / 2);
		background-position: (-50px / 2) (-50px / 2);
	}
	&.present {
		background-size: (640px / 3) (1812px / 3);
		width: 43px;
		height: (114px / 3);
		background-position: (-33px / 2) (-190px / 3);
	}
	&.phone {
		background-size: (640px / 3) (1812px / 3);
		width: 40px;
		height: (114px / 3);
		background-position: (-33px / 2) (-404px / 3);
	}
	&.typhoon {
		background-size: (640px / 3) (1812px / 3);
		width: 40px;
		height: (114px / 3);
		background-position: (-33px / 2) (-618px / 3);
	}
	&.title_phone {
		background-size: (640px / 2) (1812px / 2);
		width: 60px;
		height: 60px;
		background-position: (-50px / 2) (-404px / 2);
	}
	&.title_present {
		background-size: (640px / 2) (1812px / 2);
		width: 60px;
		height: 60px;
		background-position: (-50px / 2) (-190px / 2);
	}
	&.title_tyhoon {
		background-size: (640px / 2) (1812px / 2);
		width: 60px;
		height: 60px;
		background-position: (-50px / 2) (-618px / 2);
	}
	&.article_btn {
		background-size: (640px / 2) (1812px / 2);
		width: (540px / 2);
		height: (106px / 2);
		background-position: (-50px / 2) (-832px / 2);
	}
	&.manner_btn {
		background-size: (640px / 2) (1812px / 2);
		width: (540px / 2);
		height: (106px / 2);
		background-position: (-50px / 2) (-1038px / 2);
		margin-bottom: $spacing-42;
	}
	&.graph_btn {
		background-size: (640px / 2) (1812px / 2);
		width: (540px / 2);
		height: (106px / 2);
		background-position: (-50px / 2) (-1244px / 2);
		margin: $spacing-17 auto $spacing-42;
	}
	&.howtouse_btn {
		background-size: (640px / 2) (1812px / 2);
		width: (540px / 2);
		height: (106px / 2);
		background-position: (-50px / 2) (-1450px / 2);
		margin: $spacing-26 auto $spacing-38;
	}
	&.game_btn {
		background-size: (640px / 2) (1812px / 2);
		width: (540px / 2);
		height: (106px / 2);
		background-position: (-50px / 2) (-1656px / 2);
		display: block;
		margin: $spacing-25 auto 0;
	}
}
</style>
